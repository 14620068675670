<td class="product-row">
  <div class="product-info">
    <div class="image-col" routerLink="/companies/{{product.companyAlias}}/products/{{product.alias}}">
      <img src="https://{{s3BucketName}}.{{s3DefaultApi}}/images/{{product.profileImage}}">
    </div>
    <div class="is-flex">
      <a routerLink="/companies/{{product.companyAlias}}/products/{{product.alias}}" class="name">{{product.headingInfo?.name}}</a>
      <span class="tag verified-label" *ngIf="product.headingInfo?.verified">
        Verified
      </span>
    </div>
    <div class="empty-cell"></div>
    <div class="empty-cell"></div>
    <div class="description">
      <quill-view *ngIf="!isLongText"
                  [content]="getDescription(product)">
      </quill-view>
      <quill-view *ngIf="isLongText"
                  [content]="getDescription(product) + '<a href=\'/companies/' + product.companyAlias + '/products/' + product.alias + '\'> see more.</a>'">
      </quill-view>
    </div>
    <div class="add-info-col">
      <div class="product-property product-property__manufacturing">
        <span class="has-text-weight-bold has-text-grey-dark pr-2">
          Manufactured:
        </span>
        <span class="has-text-grey-dark">
          {{productManufacturing}}
        </span>
      </div>

      <div class="product-property product-property__stage">
        <span class="has-text-weight-bold has-text-grey-dark pr-2">
          Stage of development:
        </span>
        <span class="has-text-grey-dark">
          {{product.headingInfo?.developmentStage?.value}}
        </span>
      </div>

      <div class="product-property product-property__packaging">
        <span class="has-text-weight-bold has-text-grey-dark pr-2">
          Packaging:
        </span>
        <span class="has-text-grey-dark" *ngIf="!!product.pricingInfo.packaging">
          {{product.pricingInfo.packaging}}
        </span>
        <span class="has-text-grey-dark" *ngIf="!product.pricingInfo.packaging">
          &#8212; <!-- long dash-->
        </span>
      </div>
    </div>
    <div cs-featured-product-actions
         [isAdmin]="isAdmin"
         [product]="product"
         [hubspotForm]="hubspotForm"
         [isVerticalAlignment]="true"
         (updatedProduct)="updateProduct($event)"
         (createdProduct)="addProduct($event)">
    </div>
  </div>
</td>
