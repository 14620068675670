<header>
  <div class="header">
    <div class="header__inner-container">
      <div class="header__fake-background"></div>
      <div class="header__texts is-flex is-flex-direction-column is-justify-content-center">
        <h1>Procurement<br />for the <span class="green-text bioeconomy">Bioeconomy</span></h1>
        <div class="header__texts__subtexts is-flex is-flex-direction-column is-justify-content-center">
          <h2 class="hidden">Procurement for the bioeconomy. Cultured Supply has built a global network of suppliers transforming the way we make things.</h2>
          <p>Cultured Supply has built a global network of suppliers transforming the way we make things. They are building with nature, not oil, to create healthier, more planet-first products with superior performance.</p>
          <p>We help companies procure biobased chemicals, ingredients, materials, and finished products.</p>
        </div>
      </div>
    </div>
  </div>
</header>
<main>
  <section class="container">
    <div class="is-flex is-flex-direction-column is-align-items-center has-text-centered titles">
      <div class="titles__title">Procure Seaweed and Algae with Confidence</div>
      <div class="titles__subtitle">Seaweed and micro-algae resource center designed to help businesses make more informed decisions.</div>
    </div>
  </section>
  <section class="container is-flex is-flex-direction-column is-align-items-center algae-section">
    <div class="numbers">
      <div class="with-border">
        <div class="numbers__icon"><i class="numbers__icon fa fa-compass"></i></div>
        <div class="numbers__text">
          <div class="numbers__number">Buyers' Guides</div>
        </div>
      </div>
      <div class="with-border">
        <div class="numbers__icon"><i class="numbers__icon fa fa-chain"></i></div>
        <div class="numbers__text">
          <div class="numbers__number">Supply Chain Builder</div>
        </div>
      </div>
      <div class="">
        <div class="numbers__icon"><i class="numbers__icon fa fa-table"></i></div>
        <div class="numbers__text">
          <div class="numbers__number">Pricing Data</div>
        </div>
      </div>
    </div>
    <div>
      <a href="https://algae.culturedsupply.com/#hero" target="_blank" class="button is-success">Take me there</a>
    </div>
  </section>

  <section class="bg-image">
    <section class="container is-flex is-desktop icon-part">
      <div class="img-wrapper">
        <img src="/assets/images/new-home/bioreactor.png" alt="icon">
      </div>
      <div class="text-wrapper bioreactor-part">
        <h2 class="mb-4">Let’s build <span class="green-text">with nature</span>, not oil.</h2>
        <h4>Make nature part of the industrial ecosystem with biobased chemicals, ingredients, materials, and products.</h4>
      </div>
    </section>
  </section>
  <section class="container is-desktop phone-part">
    <div class="columns is-desktop">
      <div class="column text-wrapper">
        <h2>Looking for something specific?</h2>
        <h4>Hit us up on our procurement hotline.</h4>
        <h4>We call it the <span class="green-text">Drip Line</span>.</h4>
        <h4>Text or call <b>313-241-7544</b> with what you need; or if you are lonely. We respond to every message.</h4>
        <a class="button" href="https://wa.me/13132417544" target="_blank">
          <img src="/assets/images/new-home/whatsapp.png" alt="whatsapp">
          Text us now
        </a>
      </div>
      <div class="column img-wrapper">
        <img src="/assets/images/new-home/phone.png" alt="phone">
      </div>
    </div>
  </section>
  <section class="bg-image">
    <section class="container is-desktop drop-us">
      <h1>We are on a mission <br> to build <span class="green-text">regenerative</span> supply chains.</h1>
      <a href="contact-us">Drop us a line</a>
    </section>
  </section>
</main>
