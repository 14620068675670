import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductAddress, ProductResponse} from '../../../../../api/cs';
import {environment} from '../../../../../../environments/environment';
import {AttributesService} from '../../../../../shared/services/attributes.service';
import {PlatformImageService} from "../../../../../shared/services/platform-image.service";
import {AuthService} from "../../../../../shared/services/auth.service";

@Component({
  selector: '[cs-featured-product-bar-item]',
  templateUrl: './featured-product-bar-item.component.html',
  styleUrls: ['./featured-product-bar-item.component.scss']
})
export class FeaturedProductsBarItemComponent implements OnInit {

  @Input() product: ProductResponse;
  @Input() addresses: Array<ProductAddress>;
  @Input() hubspotForm: string;

  isAdmin: boolean;
  isLongText: boolean = false;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  productManufacturing: string;

  @Output() updatedProduct = new EventEmitter<ProductResponse>();
  @Output() cratedProduct = new EventEmitter<ProductResponse>();

  constructor(
    private authService: AuthService,
    private attributesService: AttributesService,
    public platformImageService: PlatformImageService) { }

  ngOnInit(): void {
    this.isAdmin = this.authService.isAdmin();
    this.attributesService.productAttrsLoaded$.subscribe();
    this.getProductAddresses(this.addresses);
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  getDescription(product: ProductResponse): string {
    let maxSymbolsCountInRow = window.innerWidth * 0.5 / 7;
    let maxSymbolsCount = maxSymbolsCountInRow * 6;

    return this.clipText(product.headingInfo?.description || '', maxSymbolsCountInRow, maxSymbolsCount);
  }

  private clipText(fullText: string, maxSymbolsCountInRow: number, maxSymbolsCount: number): string {
    let clippedText = '';
    let currentSymbolsCount = 0;
    let strings = fullText.split('\n') || [];
    for (let string of strings) {
      clippedText = clippedText.concat(string);
      currentSymbolsCount += string.length;
      currentSymbolsCount += maxSymbolsCountInRow - string.length % maxSymbolsCountInRow;
      let words = string.split(' ');
      let row = '';
      for (let word of words) {
        if (row.concat(word).length < maxSymbolsCountInRow) {
          row = row.concat(word);
        } else {
          currentSymbolsCount += maxSymbolsCountInRow - row.length;
          row = '';
        }
      }

      if (currentSymbolsCount > maxSymbolsCount) {
        clippedText = clippedText.substring(0, maxSymbolsCount);
        let maxRegexIndex = Math.max(
          clippedText.lastIndexOf(' '),
          clippedText.lastIndexOf(','),
          clippedText.lastIndexOf('.')
        );
        clippedText = clippedText.substring(0, maxRegexIndex - 1).concat('...');
        this.isLongText = true;
        break;
      } else {
        this.isLongText = false;
      }
    }

    if (clippedText.charAt(clippedText.length - 1) == '\n') {
      clippedText = clippedText.substring(0, clippedText.lastIndexOf('\n'));
    }

    return clippedText;
  }

  updateProduct(product: ProductResponse): void {
    this.updatedProduct.emit(product);
  }

  addProduct(product: ProductResponse): void {
    this.cratedProduct.emit(product);
  }

  private getProductAddresses(addresses: Array<ProductAddress>): void {
    let productStates = this.extractStates(addresses);
    this.productManufacturing = productStates.length > 0 ? productStates[0]! : "—";
  }

  private extractStates(addresses: ProductAddress[]): (string | undefined)[]{
    return addresses
      .map((product: ProductAddress) =>
        product.location?.substring(product.location?.indexOf(', ') + 1, product.location?.lastIndexOf(', '))
      );
  }
}
